const locale = {
  TITLE: 'Job Management',
  ALL_JOBS: 'All Open Jobs',
  PENDING: 'Pending',
  PRINTING: 'Printing',
  PACKING: 'Packing',
  CHECKING: 'Checking',
  DELIVERY: 'Distribution',
  CORRECT: 'Correction',
  COMPLETED: 'Completed',
  SEARCH_PLACEHOLDER: 'Search by patient name, patient group name and created by',
  BUTTON_PRINT_LABEL: 'Print Label',
  BUTTON_PRINT: 'Print & Pack',
  BUTTON_PACK: 'Pack',
  BUTTON_COMPLETE: 'Complete',
  BUTTON_ARCHIVE: 'Delete',
  BUTTON_PREVIEW: 'Preview',
  BUTTON_RETURN: 'Return to Job Packing',
  BUTTON_RETURN_CHECKING: 'Return to Job Checking',
  BUTTON_RETURN_DISTRIBUTION: 'Return to Distribution',
  TABLE_JOB: 'Job #',
  TABLE_BARCODE: 'Barcode',
  TABLE_PATIENT: 'Patient',
  TABLE_PATIENT_GROUP: 'Patient Group',
  TABLE_CREATED_BY: 'Created By',
  TABLE_STATUS: 'Status',
  TABLE_PACK_START_DATE: 'Pack Start Date',
  TABLE_DATE_CREATED: 'Date Created',
  TABLE_DISTRIBUTION: 'Distribution',
  TABLE_WEEK: 'Weeks',
  TABLE_CATEGORY: 'Category',
  TABLE_LAST_PRINT_DATE: 'Last Print Date',
  TABLE_BLISTER_TYPE: 'Pack Type',
  STATUS_FAILED: 'FAILED',
  STATUS_PENDING: 'PENDING',
  STATUS_PRINTING: 'PRINTING',
  STATUS_PACKING: 'PACKING',
  STATUS_CHECKING: 'CHECKING',
  STATUS_DELIVERY: 'DISTRIBUTION',
  STATUS_CORRECT: 'CORRECTION',
  STATUS_COMPLETED: 'COMPLETED',
  STATUS_CREATING: 'CREATING',
  FILTER_BLISTER_TYPE: 'Pack Type',
  FILTER_CREATED_DATE: 'Date Created',
  FILTER_PACK_START_DATE: 'Pack Start Date',
  BULK_PRINT_ERROR_TITLE: 'Bulk Print',
  BULK_PRINT_ERROR_MESSAGE: 'All selected jobs must have the same Pack Type',
  CLEAR_FILTER: 'Clear',
  DELETE_JOB_MESSAGE_SUCCESS: 'Jobs successfully deleted',
  DELETE_JOB_MESSAGE_REVERSE: 'Jobs successfully reverse',
  CONFIRM_PILL_BALANCE: 'Previous deduction of pill balance(s) will be reverted.',
  DELETE_JOB_MESSAGE_TITLE: 'Delete Jobs',
  WARNING_JOB_MESSAGE_TITLE: 'Warning',
  TABLET_SUMMARY_PATIENT: 'Patient',
  TABLET_SUMMARY_PATIENT_GROUP: 'Patient Group',
  TABLET_SUMMARY_PACK: 'Pack',
  TABLET_SUMMARY_PACK_TYPE: 'Pack Type',
  TABLET_SUMMARY_BLISTER_SIZE: 'Blister Size',
  TABLET_SUMMARY_PHOTO_PLACEHOLDER: 'Patient Photo',
  TABLET_SUMMARY_PACK_WEEK: 'Weeks',
  TABLET_SUMMARY_PACK_START_DATE: 'Pack Start Date',
  TABLET_SUMMARY_BARCODE: 'Barcode',
  PRESCRIPTION_PLACEHOLDER_APN: 'Scan Barcode',
  PRESCRIPTION_PLACEHOLDER_QTY: 'QTY',
  PRESCRIPTION_BUTTON_RECORD: 'Complete Pack',
  PRESCRIPTION_BUTTON_CONFIRM_PASS: 'Confirm Passed',
  PRESCRIPTION_BUTTON_SKIP: 'Skip',
  PRESCRIPTION_BUTTON_SKIPPED: 'Skipped',
  PRESCRIPTION_BUTTON_CORRECTION_REQUIRED: 'Correction Required',
  PRESCRIPTION_BUTTON_CORRECTION_COMPLETED: 'Correction Completed',
  PRESCRIPTION_BUTTON_DISTRIBUTE_COMPLETE: 'Complete',
  PRESCRIPTION_BUTTON_CONFIRM: 'Confirm',
  PRESCRIPTION_TABLE_DIRECTION: 'Directions',
  PRESCRIPTION_TABLE_DRUG: 'Drug',
  PRESCRIPTION_TABLE_VPB: 'VPB',
  PRESCRIPTION_TABLE_APPEARANCE: 'Appearance',
  PRESCRIPTION_TABLE_QTY: 'QTY',
  PRESCRIPTION_TABLE_REASON: 'Reason',
  PRESCRIPTION_TABLE_COMMENT: 'Comments',
  FOIL_PAGE_TITLE: 'Week {{ week }}',
  TOOL_TIP_MESSAGE: 'Not included in current print',
  SEARCH_APN_MESSAGE_NOT_FOUND_TITLE: 'Warning',
  SEARCH_APN_MESSAGE_NOT_FOUND_MESSAGE:
    'The barcode scanned has not been assigned to a medication.\nPlease confirm appropriate medication to pack.',
  SEARCH_APN_MESSAGE_NOT_MATCH_TITLE: 'Warning',
  SEARCH_APN_MESSAGE_NOT_MATCH_MESSAGE:
    "The medication scanned does not match the patient's profile.\nPlease scan correct medication.",
  SEARCH_APN_MESSAGE_SAME_GENERIC_TITLE: 'Warning',
  SEARCH_APN_MESSAGE_SAME_GENERIC_MESSAGE:
    "The medication scanned does not match the patient's profile. However it is a generic equivalent.\n\nYou have scanned:\nCODE: {{ code }}\nMedication: {{ medication }}\n\nPlease confirm this is appropriate to pack.",
  SEARCH_APN_MESSAGE_ASSIGN_THE_BARCODE:
    'The barcode scanned has not been assigned to a medication.\n Do you want to assign the barcode now? ',
  SEARCH_APN_ASSIGN_THE_BARCODE_TITLE: 'Warning',
  ASSIGN_DRUG_BARCODE_TITLE: 'Select Drug to assign barcode',
  RECORD_VPB_WARNING_TITLE: 'Warning',
  RECORD_VPB_WARNING_MESSAGE:
    'There is still unconfirmed medication(s). \nAre you sure you want to continue to record into VPB ?',
  RECORD_VPB_ERROR_TITLE: 'Error',
  RECORD_VPB_ERROR_MESSAGE: 'There is no confirmed prescription in the current Job',
  RECORD_VPB_SUCCESS_MESSAGE: 'Packing data is saved successfully',
  BUTTON_PRINT_LOCAL: 'Local Print',
  PRINTER_CONFIG_ERROR_TITLE: 'Printer Config',
  PRINTER_CONFIG_ERROR_MESSAGE: 'Configured printer could not be accessed',
  PRINTING_ERROR_TITLE: 'Print Error',
  BUTTON_GENERATE_DATA_FILE: 'Generate Data File',
  GENERATE_DATA_SUCCESS: 'Data file is generated successfully',
  ALPACA: 'ALPACA',
  PRINT_LABEL_MODAL_TITLE: 'Print Label',
  PRINT_LABEL_MODAL_START_POSITION: 'Start from Label',
  PRINT_LABEL_MODAL_ERROR_TITLE: 'Print Label',
  PRINT_LABEL_MODAL_ERROR_MESSAGE: 'This action for PPAK job only',
  CONFIRM_PASSED_MESSAGE: 'Job has been updated successfully',
  COMPLETE_DISTRIBUTE_MESSAGE: 'Job has been updated successfully',
  REJECT_MODAL_TITLE: 'Correction Required:',
  REJECT_MODAL_OPTION_INCORRECT_DRUG: 'Incorrect Drug',
  REJECT_MODAL_OPTION_INCORRECT_QTY: 'Incorrect Qty',
  REJECT_MODAL_OPTION_OTHER: 'Other',
  REJECT_MODAL_COMMENT: 'Comment',
  CATEGORY_CYTOTOXIC: 'Cytotoxic',
  CATEGORY_STANDARD: 'STND',
  CATEGORY_S8: 'S8',
  CATEGORY_WARFARIN: 'Warfarin',
  CATEGORY_S4: 'S4D',
  CATEGORY_SHORTCOURSE: 'Short Course',
  CATEGORY_SINEMET: 'Parkinson’s ',
  CATEGORY_PRN: 'PRN',
  COMMENT_MODAL_DISTRIBUTION: 'Distribution',
  COMMENT_MODAL_MUTILPLE_TILE: 'Selected Jobs',
  COMMENT_MODAL_NOTE: 'This comment will be saved for all selected jobs.',
  COMMENT_MODAL_COMMENT: 'Comment',
  DISTRIBUTION_PICK_UP: 'Pick up',
  DISTRIBUTION_DELIVERY: 'Delivery',
  FOILPAGE_NOTE: 'Total number of pieces per timeslot',
  STATUS_LABEL: 'Selected Status',
  STATUS_PLACEHOLDER: 'All Statuses',
  BARCODE_MODAL_PATIENT: 'Patient',
  BARCODE_MODAL_GROUP: 'Group',
  BARCODE_MODAL_DISTRIBUTION: 'Distribution',
  BARCODE_TABLE_BARCODE: 'Barcode',
  BARCODE_TABLE_PACK_START_DATE: 'Pack Start Date',
  BARCODE_TABLE_STATUS: 'Status',
  QUICK_PACKING_SUCCESS: 'Job has been packing successfully',
  QUICK_CHECKING_SUCCESS: 'Job has been checking successfully',
  QUICK_DISTRIBUTION_SUCCESS: 'Job has been distribution successfully',
  BARCOE_MODAL_DESCRIPTION: 'This comment will be saved for all selected jobs',
  BARCOE_MODAL_COMPLETED_COMMENT: 'Comment',
  BARCOE_MODAL_COMPLETED_DATE: 'Date',
  PRESCRIPTION_CHANGE_MESSAGE:
    "An update has been made to this medication profile.\nPlease review patient's profile before proceeding. \t\t",
  PRESCRIPTION_CHANGE_TITLE: 'Profile Update Alert',
  BARCODE_ALERT_VIEW_CHANGES: 'View Changes',
  BARCODE_VIEW_CHANGES_DESCRIPTION: 'Medication Profile has been updated. Please refer to Patient Profile.',
  SKIP_MODAL_TITLE: 'Reason for Skipping: {{drugName}}',
  CONFIRM_MODAL_PLACE_HOLDER: 'Comment*',
  WARNING_DRUG_NOT_PACKED_HOVER: 'Drug is not packed. Hover over drug entry to view reason provided',
  BARCODE_ALERT_INCOMPLETE_PACK: 'Incomplete Pack',
  CHECKING_GO_BACK_PATIENT_PROFILE: 'Go to patient profile',
  ROBOT_PACK: 'Robot Pack',
  CYTOTOXIC_DRUG_WARNING: 'CYTOTOXIC DRUG - Use no touch technique when handling this medication',
  ALPACA_CHECKBOX_LABEL: 'Alpaca',
  STATUS_DELETED: 'DELETED',
  STAGE_DELETED: 'Deleted',
  DELETE_JOB_MESSAGE_CONFIRM_PRINTING:
    'Deleting packs will reverse VPB. Are you sure you want to continue?\n  Click Delete if you wish to NOT reverse VPB.',
  DELETE_JOB_MESSAGE_CONFIRM: 'Are you sure you want to delete packs?',
  DELETE_SKIPING_JOB_MESSAGE_CONFIRM:
    'Deleting packs will reverse VPB. Are you sure you want to continue?\n  Click Skip if you wish to NOT reverse VPB.',
  DELETED_PACKED_ERROR_MESSAGE: 'This pack has been deleted and cannot be packed.',
  WARNING_PATIENT_WITH_INCOMPLETE_PROFILES: 'Patient with incomplete profiles',
};

export default locale;
