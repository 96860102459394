const locale = {
  TITLE: 'Patient Management',
  HEADER_TITLE: 'Patients',
  HEADER_SEARCH_TEXT_PLACEHOLDER: 'Search patient...',
  HEADER_ADD_PATIENT_BUTTON_FULL: 'Add New Patient',
  HEADER_ADD_PATIENT_BUTTON_SHORT: 'New',
  ARCHIVE_PATIENT_CONFIRM_TITLE: 'Delete Patient',
  ARCHIVE_PATIENT_CONFIRM_CONTENT: 'Are you sure you want to delete patient {{ firstName }} {{ lastName }}?',
  FORCE_ARCHIVE_PATIENT_CONFIRM_CONTENT:
    '{{ firstName }} {{ lastName }} still has active foils or pack jobs. Do you want to continue?',
  PATIENT_TABLE_HEADER_DISPENSE_ID: 'Dispense ID',
  PATIENT_TABLE_HEADER_FIRST_NAME: 'First Name',
  PATIENT_TABLE_HEADER_LAST_NAME: 'Last Name',
  PATIENT_TABLE_HEADER_PATIENT_GROUP: 'Patient Group',
  PATIENT_TABLE_HEADER_LAST_CHECK_DATE: 'Last Checked',
  PATIENT_TABLE_HEADER_NOTE: 'Notes',
  PATIENT_TABLE_HEADER_STATUS: 'Status',
  PATIENT_TABLE_HEADER_DISTRIBUTION: 'Distribution',
  PATIENT_TABLE_HEADER_6CPA_STATUS: 'PPA Status',
  PATIENT_LIST_NO_SEARCH_RESULT: 'No search results',
  PATIENT_LIST_NO_DATA: 'No data to display',
  ARCHIVE_PATIENT_SUCCESS: 'Patient has been deleted successfully',
  TAB_PATIENT_DETAILS: 'Patient Details',
  TAB_PATIENT_ALLERGIES_AND_CONSIDERATIONS: 'Allergies & Considerations',
  TAB_PATIENT_NOTES: 'Notes',
  TAB_PATIENT_MEDICATION_PROFILE: 'Medication Profile',
  TAB_PATIENT_MEDICATION_HISTORY: 'History',
  TAB_PATIENT_DOCTORS_CONTACTS: 'Doctors & Contacts',
  TAB_PATIENT_DOCUMENTS: 'Documents',
  DOCUMENTS_SECTION: 'Documents',
  DOCTORS_SECTION: 'Doctors',
  CONTACTS_SECTION: 'Emergency Contacts',
  ADD_DOCTOR: 'Add Doctor',
  ADD_CONTACT: 'Add Contact',
  ADD_DOCUMENT: 'Add Document',
  DOCUMENT_DESCRIPTION_HEADER: 'Documents',
  DOCUMENT_TYPE_HEADER: 'File Type',
  DOCUMENT_CREATED_BY_HEADER: 'Created By',
  DOCUMENT_CREATED_AT_HEADER: 'Created At',
  DOCUMENT_DESCRIPTION_LABEL: 'Description',
  DOCUMENT_FILE_LABEL: 'File',
  UPDATE_PATIENT_DETAILS_SUCCESSFUL: 'Patient detail has been updated successfully!',
  UPDATE_PATIENT_DETAILS_FAIL: 'Error',
  UPDATE_PATIENT_DETAILS_INACTIVE_GROUP: 'Inactive Patient Group',
  AVATAR: 'Patient Photo',
  DAA_FUNDING: 'PPA Funding',
  DOCTOR: 'Doctor',
  IMAGE: 'Image',
  MEDICATE_NUMBER: 'Medicare Number',
  PATIENT_GROUP_NAME: 'Patient Group',
  UPDATE_TITLE: 'Update Patient page',
  FROM_DATE: 'From',
  TO_DATE: 'To',
  DRUG_NAME: 'Drug Name',
  GENERIC: 'Generic',
  STATE: 'State',
  DATE_CHANGE: 'Date Change',
  DATE: 'Date',
  PATIENT_HISTORY: 'Patient History',
  USER_COMMENT: 'Comment',
  HISTORY_DIFFERENT_VIEW: 'History Different View',
  ITEM: 'Item',
  OLD_VALUE: 'Previous',
  NEW_VALUE: 'Current',
  ACTIONS: 'Actions',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  PHONE: 'Phone',
  EMAIL: 'Email',
  SELECT_DOCTOR: 'Select Doctor',
  ADD_DOCTOR_TO_PATIENT_SUCCESS: 'Doctor has been add to this patient successfully!',
  REMOVE_DOCTOR_FROM_PATIENT_SUCCESS: 'Doctor has been removed from patient successfully!',
  UPDATE_DOCTOR_FROM_PATIENT_SUCCESS: 'Doctor details has been updated successfully!',
  UPDATE_DOCTOR: 'Doctor',
  PATIENT_TITLE: 'Title',
  PATIENT_FIRST_NAME: 'First Name',
  PATIENT_LAST_NAME: 'Last Name',
  PATIENT_IMPORT_STATUS: 'Status',
  PATIENT_PREFERRED_NAME: 'Preferred Name',
  PATIENT_DOB: 'Date of Birth',
  PATIENT_Age: 'Age',
  PATIENT_VISION_IMPAIRED: 'Vision Impaired',
  PATIENT_STATUS: 'Patient status',
  PACKING_STATUS: 'Packing status',
  DISTRIBUTION_STATUS: 'Distribution Status',
  PATIENT_EMAIL: 'Email',
  PATIENT_MOVEMENT: 'Movement',
  PATIENT_ROOM: 'Room',
  PATIENT_PATIENT_GROUP: 'Patient Group',
  PATIENT_PATIENT_GROUP_TYPE: 'Patient Group Type',
  PATIENT_AREA: 'Area',
  PATIENT_GROUP_WARD: 'Ward',
  PATIENT_GENDER: 'Gender',
  PATIENT_URN: 'URN/MRN',
  PATIENT_IHI: 'IHI',
  PATIENT_PHOTO_TAKE_DATE: 'Photo Taken Date',
  PATIENT_SUSPEND_FROM: 'Suspend Packing From',
  PATIENT_SUSPEND_TO: 'Suspend Packing To',
  PATIENT_DAA_FUNDING: 'PPA Funding',
  PATIENT_MEDICARE_NO: 'Medicare Number',
  PATIENT_MEDICARE_NO_VALIDATION: 'External Patient ID should be Integer',
  PATIENT_DVA_NO: 'DVA Number',
  PATIENT_ADDRESS: 'Street',
  PATIENT_SUBURB: 'Suburb',
  PATIENT_STATE: 'State',
  PATIENT_COUNTRY: 'Country',
  PATIENT_POSTAL_CODE: 'Postcode',
  PATIENT_WARD: 'Ward',
  PATIENT_DIAGNOSIS: 'Diagnosis',
  PATIENT_DIAGNOSIS_BUTTON_TEXT: 'Add new Diagnosis',
  PATIENT_CONCESSION_TYPE: 'Concession Type',
  PATIENT_REPAT_NUMBER: 'DVA Number',
  PATIENT_MEDICARE_EXPIRY_DATE: 'Medicare Expiry Date',
  PATIENT_MEDICARE_EXPIRY_DATE_VALIDATE: 'Medicare Expiry Date Incorrect',
  PATIENT_VALIDATE_NUMBER: 'Must contain numbers',
  PATIENT_CONCESSION_NO: 'Concession Number',
  PATIENT_SAFETY_NET_NO: 'Safety Net Number',
  PATIENT_DIABETIC_NO: 'NDSS Number',
  PATIENT_EXTERNAL_ID: 'Patient Dispense ID',
  PATIENT_IMPORTED_DATE: 'Date Imported',
  CHECK_PROFILE_SUCCESS: 'Profile has been checked successfully',
  PROFILE_STATUS: 'Patient status:',
  PROFILE_LAST_MED_MODIFIED: 'Last Modified:',
  PROFILE_LAST_CHECKED_DATE: 'Last Checked Date:',
  CHECK_PROFILE_HEADER: 'Check Profile',
  CHECK_PROFILE_CONTENT: 'This patient will be flagged as checked. Are you sure you want to proceed?',
  REMOVE_PRESCRIPTION_CONTENT:
    'Are you sure you want to delete {{drugName}}?\nThis will be logged in the history as drug deleted.',
  REMOVE_PRESCRIPTION_TITLE: 'Delete Drug',
  ALL_MEDICATIONS: 'All Medications',
  CHECK_PROFILE: 'Check Profile',
  CREATE_FOIL: 'Create Foil',
  DELETE_PACK_TITLE: 'Delete Pack',
  DELETE_PACK_MESSAGE: 'Are you sure you want to delete {{ name }}?',
  DELETE_UNACTIVE_PACK_MESSAGE: 'There are active drugs in this pack. Are you sure you want to delete {{name}}?',
  BLISTER_HEADING_VALIDATION: 'Must provide all values',
  BLISTER_HEADING_TOO_LONG: 'Must be less than 32 character',
  SACHET_HEADING_VALIDATION: 'Must provide at least four values',
  SELECT_DRUG: 'Select Drug',
  SELECT_PREFERRED_DRUG: 'Select Preferred Drug',
  ADD_PRESCRIPTION: 'Add Prescription',
  NOTE_INPUT_LABEL: 'Notes',
  NOTE_INPUT_USAGE: 'Usage',
  NOTE_ARCHIVE_MESSAGE: 'Are you sure you want to delete this note?',
  NOTE_ARCHIVE_TITLE: 'Delete Note',
  NOTE_DIALOG_TITLE: '{{action}} Note',
  DOCUMENT_DIALOG_TITLE: '{{action}} Document',
  DOCUMENT_DELETE_MESSAGE: 'Are you sure you want to delete this document?',
  DOCUMENT_DELETE_TITLE: 'Delete document',
  QUICK_ADD_DRUG: 'Quick Add Drug',
  PRESCRIPTION_PHOTO: 'Photo',
  PACK: 'Pack',
  DRUG: 'Drug',
  CODE: 'Code',
  DIRECTION: 'Directions',
  NOTES: 'Note',
  CATEGORY: 'Category',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  FREQUENCY: 'Frequency',
  FOIL: 'Pack',
  PRN: 'PRN',
  DOSE: 'Dose',
  ALLERGIES_SECTION: 'Allergies',
  CONSIDERATIONS_SECTION: 'Considerations',
  ADD_ALLERGY: 'Add Allergy',
  CHANGE_UNKNOWN_ALLERGIES_TITLE: 'Change No Known Allergies Confirmation',
  CHANGE_UNKNOWN_ALLERGIES_CONTENT: 'Are you sure you want to change {{isNoAllergy}} Known Drug Allergies?',
  NO_KNOWN_DRUG_ALERT_OR_ALLERGY: 'No Known Drug Alert or Allergy',
  DRUG_OR_OTHER: 'Drug (or other)',
  REACTION_TYPE: 'Reaction/Type',
  EMPTY_STATUS_TITLE: 'Cannot get any packing statuses from the server',
  EMPTY_STATUS_MESSAGE: 'Do you want to reload packing status?',
  PLEASE_SELECT_DOCTOR_FIRST: 'Please select doctor first',
  PATIENT_DETAIL_SECTION: 'Patient Details',
  PATIENT_GROUP_DETAIL_SECTION: 'Patient Group',
  CONTACT_INFO_SECTION: 'Contact Information',
  PATIENT_IDENTIFICATION_SECTION: 'Identification',
  ADD_DRUG: 'Add Drug',
  EDIT_LABEL: 'Edit Label',
  DELETE_PACK: 'Delete Pack',
  PRINT_PACK: 'Print Pack',
  EDIT_PACK: 'Edit Pack',
  REPORT: 'Print Report',
  SIGNING_SHEET: 'Print Signing Sheet',
  UPLOAD_IMAGE: 'Upload image',
  REMOVE_IMAGE: 'Remove',
  CREATE_MANUALLY: 'Create Manually',
  CREATE_ALLERGY_SUCCESS: 'Allergy have been added successfully',
  UPDATE_ALLERGY_SUCCESS: 'Allergy have been updated successfully',
  DELETE_ALLERGY_SUCCESS: 'Allergy have been deleted successfully',
  ARCHIVE_ALLERGY_CONFIRM_TITLE: 'Delete Allergy',
  ARCHIVE_ALLERGY_CONFIRM_CONTENT: 'Are you sure you want to delete entry for {{drugName}} allergy?',
  ALLERGY_DRUG_HEADER: 'Drug',
  ALLERGY_REACTION_HEADER: 'Reaction/Type',
  ALLERGY_DATE_HEADER: 'Date Recorded',
  SWALLOW_DIFFICULTIES: 'Swallowing Difficulties',
  COGNITIVE_IMPAIRMENT: 'Cognitive Impairment',
  DEXTERITY_DIFFICULTIES: 'Dexterity Difficulties',
  RESISTIVE_TO_MEDICINE: 'Resistive To Medicine',
  NIL_BY_MOUTH: 'Nil By Mouth',
  SELF_ADMINISTER: 'Self Administer',
  OTHER_CONSIDERATION: 'Other',
  ALLERGY_DIALOG_TITLE: '{{action}} Allergy',
  ADD_FOIL_SUCCESS_MESSAGE: 'Pack has been added successfully',
  UPDATE_FOIL_SUCCESS_MESSAGE: 'Pack has been updated successfully',
  DELETE_FOIL_SUCCESS_MESSAGE: 'Pack has been deleted successfully',
  GENERIC_NAME: 'Generic Name',
  NOTES_SECTION: 'Notes',
  ADD_NOTE: 'Add Note',
  NOTE_CONTENT_HEADER: 'Note',
  NOTE_DISPLAY_HEADER: 'Display',
  NOTE_CREATED_BY_HEADER: 'Created By',
  NOTE_CREATED_AT_HEADER: 'Created At',
  NOTES_NAV_TILE: 'Notes',
  PACKED_PRESCRIPTION_SECTION: 'Packed',
  UNPACKED_PRESCRIPTION_SECTION: 'Non-Packed',
  PACKED_PRN_PRESCRIPTION_SECTION: 'Packed PRN',
  UNPACKED_PRN_PRESCRIPTION_SECTION: 'Non-Packed PRN',
  CEASED_PRESCRIPTION_SECTION: 'Ceased',
  ADD_BLISTER_FOIL: 'Add Blister Pack',
  ADD_SACHET_FOIL: 'Add Sachet Pack',
  CONTACT_NAME_HEADER: 'Name',
  CONTACT_FIRST_NAME_HEADER: 'First Name',
  CONTACT_LAST_NAME_HEADER: 'Last Name',
  CONTACT_PHONE_HEADER: 'Phone',
  CONTACT_EMAIL_HEADER: 'Email',
  CONTACT_ADDRESS_HEADER: 'Address',
  CONTACT_NOTE_HEADER: 'Notes',
  CONTACT_DIALOG_TITLE: '{{action}} Contact',
  CONTACT_ARCHIVE_MESSAGE: 'Are you sure you want to delete {{firstName}} {{lastName}}?',
  CONTACT_ARCHIVE_TITLE: 'Delete Contact',
  CONTACT_FIRST_NAME: 'First Name',
  CONTACT_LAST_NAME: 'Last Name',
  CONTACT_ADDRESS: 'Street',
  CONTACT_PHONE: 'Phone',
  CONTACT_EMAIL: 'Email',
  CONTACT_NOTES: 'Notes',
  DIALOG_SELECT_DRUG_NAME: 'Name',
  DIALOG_SELECT_DRUG_DESCRIPTION: 'Description',
  DIALOG_SELECT_DRUG_GENERIC: 'Generic',
  DIALOG_SELECT_DRUG_CODE: 'Code',
  DIALOG_SELECT_DRUG_TYPE: 'Type',
  DIALOG_SELECT_IMAGE: 'Image',
  DIALOG_EDIT_PACK_LABEL: 'Label',
  DIALOG_EDIT_PACK_BLISTER_TYPE: 'Type',
  DIALOG_EDIT_PACK_STATUS: 'Status',
  DIALOG_EDIT_PACK_BLISTER_SIZE: 'Blister Size',
  DIALOG_EDIT_PACK_FREQUENCY: 'Frequency',
  DIALOG_EDIT_PACK_DOSE_TYPE: 'Type',
  DIALOG_EDIT_PACK_COMMENT: 'Comment',
  DIALOG_EDIT_PACK_WARNING_LABEL: 'Warning Label',
  DIALOG_EDIT_PACK_ALERT: 'Alert',
  DIALOG_EDIT_PACK_TITLE: 'Edit Pack',
  DIALOG_EDIT_PACK_HEADING: 'Headings',
  DIALOG_EDIT_PACK_ALPACA: 'Handled by Automation',
  DIALOG_EDIT_PACK_TYPE_PLACEHOLDER: 'Select Consumables...',
  DIALOG_EDIT_PACK_FREQUENCY_PLACEHOLDER: 'Select Frequency...',
  DIALOG_EDIT_PACK_DOSE_PLACEHOLDER: 'Select Dosing...',
  PRESCRIPTION_PACK_DOSES_AS: 'Pack Doses As:',
  PRESCRIPTION_PACK_DOSES_TYPE_STANDARD: 'STND',
  PRESCRIPTION_PACK_DOSES_TYPE_INSULIN: 'Insulin',
  PRESCRIPTION_PACK_DOSES_TYPE_S8DRUG: 'S8',
  PRESCRIPTION_PACK_DOSES_TYPE_CYTOTOXIC: 'Cytotoxic',
  PRESCRIPTION_PACK_DOSES_TYPE_WARFARIN: 'Warfarin',
  PRESCRIPTION_PACK_DOSES_TYPE_ISS4N: 'S4D',
  PRESCRIPTION_PACK_DOSES_TYPE_SHORTCOURSE: 'Short Course',
  PRESCRIPTION_PACK_DOSES_TYPE_SINEMET: 'Parkinson’s ',
  PRESCRIPTION_BLISTER_HEADER_TIMESLOT: 'Timeslot Headers',
  PRESCRIPTION_BLISTER_HEADER_UNIT: 'Units/Timeslots',
  PRESCRIPTION_CEASE_DRUG: 'Cease Drug',
  PRESCRIPTION_ADD_TITLE: 'Add',
  PRESCRIPTION_EDIT_TITLE: 'Edit',
  PRESCRIPTION_UN_CEASE_DRUG: 'Recommence Drug',
  DIALOG_ADD_PACK_TITLE: 'Add Pack',
  INDIVIDUAL_PACK_BLISTER_TYPE: 'Pack Type',
  INDIVIDUAL_PACK_DOSE_TYPE: 'Dosing',
  INDIVIDUAL_PACK_FREQUENCY: 'Frequency',
  INDIVIDUAL_PACK_STATUS: 'Status',
  INDIVIDUAL_PACK_WARNING: 'Caution/Warning',
  INDIVIDUAL_PACK_ALERT: 'Alert',
  INDIVIDUAL_PACK_COMMENT: 'Comment',
  INDIVIDUAL_BLISTER_SIZE: 'Blister Size',
  INDIVIDUAL_AUTOMATION: 'Automation',
  VALIDATE_PATIENT_EXTERNAL_ID_POSITIVE: 'External patient ID	should be greater than or equal to {{ min }}',
  VALIDATE_PATIENT_EXTERNAL_ID_LESS_THAN: 'External patient ID should be less than or equal to {{ max }}',
  VALIDATE_PATIENT_EXTERNAL_ID_INTEGER: 'External patient ID should be integer',
  CONFIRM_MODAL_TITLE: 'Comment',
  CONFIRM_MODAL_PLACE_HOLDER: 'Comment*',
  DUPLICATE_PRESCRIPTION_MESSAGE:
    "This drug already exists in this patient's profile. Are you sure you want to proceed?",
  DUPLICATE_PRESCRIPTION_TITLE: 'Duplicated Prescription',
  CEASE_CONFIRM_MODAL_MESSAGE: 'Are you sure you want to cease this medication ?',
  CEASE_CONFIRM_MODAL_TITLE: 'Cease drug',
  CHECK_PROFILE_MESSAGE_CHECKED: 'Profile checked on {{ checkedDate }} by {{ checkedUserName }}',
  CHECK_PROFILE_MESSAGE_NOT_CHECKED: 'Not Checked',
  BUTTON_IMPORT_FROM_DISPENSE: 'Import From Dispense',
  DIALOG_IMPORT_FROM_DISPENSE_TITLE: 'Import Patient From Dispense',
  DIALOG_IMPORT_FROM_DISPENSE_MESSAGE: 'Import patient from dispense successfully',
  BUTTON_ADD_NEW_DRUG: 'Add Custom Drug',
  DIALOG_QUICK_ADD_DRUG_TITLE: 'Add Custom Drug',
  TAB_ADD_DRUG: 'Add Drug',
  TAB_IMPORT_DRUG: 'Import Drug',
  INCLUDE_INACTIVE_PATIENTS: 'Include Inactive Patients',
  VALIDDATE_DOSE_MESSAGE: 'Must provide valid dose',
  BUTTON_DELETE_PATIENT: 'Delete Patient',
  DELETE_DOCTOR_FROM_PATIENT_TITLE: 'Delete Doctor',
  DELETE_DOCTOR_FROM_PATIENT_MESSAGE:
    'Are you sure you want to delete doctor {{ firstName }} {{ lastName }} from this patient?',
  CREATE_PATIENT_SUCCESSFULLY_MESSAGE: 'Patient created successfully',
  CHECK_EXCEEDED_PRESCRIPTION_TITLE: 'Warning',
  CHECK_EXCEEDED_PRESCRIPTION_MESSAGE:
    'You have exceeded the maximum packed medications entered in {{ foil }} in the same category {{ category }}. Would you like to create an additional pack?',
  CHECK_EXCEEDED_PRESCRIPTION_MESSAGE_UPDATE:
    'Maximum no. of packed medications has been reach for this blister pack. Please select another blister pack.',
  BUTTON_PRINT_HISTORY_REPORT: 'Print History Report',
  BUTTON_HISTORY_VIEW_MEDICATION: 'Medication History',
  BUTTON_HISTORY_VIEW_PATIENT: 'Patient History',
  BUTTON_ADD_AND_NEW: 'Add & New',
  UPLOAD_PATIENT_PHOTO: 'Upload Patient Photo',
  COUNT_PATIENT_SELECTED: '{{ count }} Patients Selected',
  NO_BRAND_SUBSTITUTION: 'No Brand Substitution',
  IMPORT_TO_PATIENT_GROUP: 'Import To Patient Group',
  IMPORT_DISPENSE_PATIENT_SUCCESS: 'Patients has been imported successfully',
  BUTTON_IMPORT_PATIENT: 'Import Patients',
  PATIENT_SELECTION: 'Patient Selection',
  PATIENT_GROUP_SELECTION: 'Patient Group Selection',
  STATUS_DISPENSE: 'Import new drugs from scripts dispensed in the last',
  PATIENT_ID: 'Patient #',
  PATIENT_ADDRESS_LABEL: 'Address',
  VALIDATION_MAX_DATE_NEGATIVE: 'Please provide value greater than 0',
  PATIENT_STATUS_IMPORTED: 'Imported',
  PATIENT_STATUS_NOT_IMPORTED: 'Not Imported',
  FILTER_PATIENT_STATUS_PLACEHOLDER: 'All Statuses',
  FILTER_PATIENT_STATUS_LABEL: 'Selected Status',
  BUTTON_SAVE: 'Save',
  SELECT_PACK: 'Select Pack',
  SELECT_PACK_DESCRIPTION: 'Please select the pack that you would like to add the drug to',
  BUTTON_ADD_NEW_DOCTOR: 'Add New Doctor',
  WARNING_CHANGE_FREQUENCY: 'Please remove doses in mealtimes not required for {{frequency}}.',
  PACK_FREQUENCY_CHANGE_TITLE: 'Warning',
  BUTTON_VIEW_PILL_BALANCE: 'View Pill Balance',
  SORT_CANISTER: 'C',
  SORT_MANUAL: 'M',
  DETAILS: 'Details',
  NO_KNOWN_ALLERGY: 'No known allergy',
  CHECK_EXCEEDED_PRESCRIPTION_DESCRIPTION:
    'The maximum no. of packed medications (16) has been reached for {{foilName}}.\n {{detailOption}}',
  EXCEEDEED_MODAL_ADD_NEW_PACK: 'Or \n\n ',
  ALLERGY_ALERT_MESSAGE: 'Patient has an allergy to this drug - Are you sure you want to continue?',
  ALLERGY_ALERT_TITLE: 'Allergy Alert',
  ADD_NEW_BLISTER_PACK: 'Add New Blister Pack',
  INCLUDE_SUPPENDED_PATIENT: 'Include Suspended Patients',
  PATIENT_INACTIVE_WILL_CEASE_ALL_MEDICATIONS:
    'Are you sure you want to make {{ firstName }} {{ lastName }} inactive? All medications will be ceased.',
  WARNING: 'Warning',
  DOCTOR_POSITIVE_VALIDATION: 'Must be greater or equal to 1.',
  DOCTOR_PRIORITY_NUMBER_EXSITING: 'The priority number already exists.',
  DAYS_30_DISPENSING: '30 Day Dispensing',
  USER: 'User',
  SIMILAR_NAME_ALERT: 'Similar name alert',
  NOTE_DETAIL_DISPLAY_ZONE: 'This note will be displayed in {{area}}',
  NOTE_MODAL_NO_DISPLAY: 'No message will be displayed',
  PATIENT_ADD_NEW_NOTE_MODAL: 'Please select area for note to be displayed',
};

export default locale;
